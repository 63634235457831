<template>
  <div
    class="home"
    v-bind:class="$vuetify.breakpoint.smAndDown ? 'mx-2' : 'mx-0 px-0'"
    style="margin-bottom: 50px; margin-top: 80px"
  >
    <v-row no-gutters justify="center" class="mt-12 text-center">
      <v-col v-bind="main_card_width">
        <v-card flat color="transparent" class="pa-2">
          <v-card-text class="text-center white--text">
            <v-row class="rounded_row_white black--text pa-6 pb-12">
              <v-col cols="12" class="text-left d-flex align-center">
                <div>
                  <h1 class="main_heading_on_white">
                    <!-- <vue-typer
                      :text="['Benvenuto in AVMSquare']"
                      :repeat="0"
                      :shuffle="true"
                      initial-action="typing"
                      :pre-type-delay="0"
                      :type-delay="20"
                      :pre-erase-delay="2000"
                      :erase-delay="250"
                      erase-style="select-all"
                      :erase-on-complete="false"
                      caret-animation="solid"
                      class="black--text"
                    ></vue-typer> -->

                    <!-- Competenza e disponibilità al vostro servizio -->
                    Benvenuto in AVMSquare!
                  </h1>
                  <hr class="my-2 header_separator" />

                  <h2 class="mt-0 main_subheading_on_white">
                    <!-- <vue-typer
                      :text="[
                        'Disponibilità e professionalità al vostro servizio',
                      ]"
                      :repeat="0"
                      :shuffle="true"
                      initial-action="typing"
                      :pre-type-delay="2000"
                      :type-delay="20"
                      :pre-erase-delay="2000"
                      :erase-delay="250"
                      erase-style="select-all"
                      :erase-on-complete="false"
                      caret-animation="solid"
                      class="black--text"
                    ></vue-typer> -->
                    Competenze informatiche al tuo servizio.
                    <!-- Disponibilità e professionalità al vostro servizio -->
                  </h2>
                </div>
              </v-col>

              <!-- -------------------- -->

              <v-col
                cols="12"
                offset-sm="3"
                sm="6"
                offset-lg="2"
                lg="8"
                class="black--text"
              >
                <v-row>
                  <!-- 1  -->
                  <v-col
                    data-aos="fade-right"
                    cols="12"
                    sm="6"
                    class="d-flex justify-center pb-0 px-2 service_box"
                    style="min-height: 200px"
                  >
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        class="pb-0 d-flex justify-center align-center"
                      >
                        <router-link
                          :to="{ name: 'OurServicesAssistenzaConsulenza' }"
                          style="display: flex"
                        >
                          <v-img
                            contain
                            :max-height="
                              $vuetify.breakpoint.smAndDown ? 100 : 150
                            "
                            :max-width="
                              $vuetify.breakpoint.smAndDown ? 100 : 150
                            "
                            :src="
                              require('@/assets/imgs/pages/home_assistenza2.png')
                            "
                            alt="Immagine assistenza"
                            style="
                              width: 75%;
                              max-width: 150px;
                              max-height: 80px;
                            "
                          />
                        </router-link>
                      </v-col>
                      <v-col cols="12" style="height: 60px">
                        <hr class="service_separator" />
                        <router-link
                          class="service_link"
                          :to="{ name: 'OurServicesAssistenzaConsulenza' }"
                        >
                          <h3 class="service_undertitle">
                            Assistenza Informatica
                          </h3>
                        </router-link>
                      </v-col>
                    </v-row>
                  </v-col>

                  <!-- 2  -->
                  <v-col
                    data-aos="fade-left"
                    cols="12"
                    sm="6"
                    class="d-flex justify-center pb-0 px-2 service_box"
                    style="min-height: 200px"
                  >
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        class="pb-0 d-flex justify-center align-center"
                      >
                        <router-link
                          :to="{ name: 'OurServicesSviluppo' }"
                          style="display: flex"
                        >
                          <v-img
                            contain
                            :max-height="
                              $vuetify.breakpoint.smAndDown ? 100 : 150
                            "
                            :max-width="
                              $vuetify.breakpoint.smAndDown ? 100 : 150
                            "
                            :src="
                              require('@/assets/imgs/pages/home_sviluppo.png')
                            "
                            alt="Immagine assistenza"
                            style="
                              width: 75%;
                              max-width: 150px;
                              max-height: 80px;
                            "
                          />
                        </router-link>
                      </v-col>
                      <v-col cols="12" style="height: 60px">
                        <hr class="service_separator" />
                        <router-link
                          class="service_link"
                          :to="{ name: 'OurServicesSviluppo' }"
                        >
                          <h3 class="service_undertitle">Analisi e sviluppo</h3>
                        </router-link>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>

              <!-- -------------------- -->
            </v-row>

            <!-- OBIETTIVI -->
            <v-row data-aos="fade-right" class="rounded_row_black">
              <v-col cols="12" data-aos="fade-up">
                <h2 class="main_heading my-12" style="line-height: 1">
                  Quali sono i nostri obiettivi?
                </h2>
              </v-col>
              <!-- Risolvere -->
              <v-col
                data-aos="fade-right"
                cols="12"
                md="5"
                offset-md="1"
                class="mt-6"
              >
                <v-row>
                  <v-col cols="12" class="text-center">
                    <h2 class="target_title">
                      <TextScrambler
                        text="Risolvere"
                        :scrambleDuration="20"
                        class="scrambler-anim-top"
                      />
                    </h2>
                    <p class="mt-2 topic_text">
                      [...è l’attività che consente di porre rimedio ad un
                      problema giungendo ad una soluzione...]
                    </p>
                    <v-icon color="white" x-large>mdi-circle-small</v-icon>
                    <v-icon color="white" x-large>mdi-circle-small</v-icon>
                    <v-icon color="white" x-large>mdi-circle-small</v-icon>
                    <p class="topic_proposition">
                      Identificare problemi e necessità da affrontare e
                      risolvere adottando le migliori e più adatte soluzioni
                      tecnologiche disponibili
                    </p>
                  </v-col>
                  <!-- <v-col cols="12" class="text-center py-0 my-0">Vedi</v-col>
                  <v-col cols="12" class="py-0 my-0">
                    <v-btn
                      :to="{ name: 'ServiceSupport' }"
                      block
                      text
                      color="white"
                      >Servizi Assistenza</v-btn
                    >
                  </v-col> -->
                </v-row>
              </v-col>
              <!-- Consigliare -->
              <v-col
                data-aos="fade-left"
                v-if="delay_500"
                cols="12"
                md="5"
                offset-md="5"
                class="mt-6"
              >
                <v-row>
                  <v-col cols="12" class="text-center">
                    <h2 class="target_title">
                      <TextScrambler
                        text="Consigliare"
                        :scrambleDuration="20"
                        class="scrambler-anim-top"
                      />
                    </h2>
                    <p class="mt-2 topic_text">
                      [...offrire, proporre, raccomandare come utile, dare
                      consigli...]
                    </p>
                    <v-icon color="white" x-large>mdi-circle-small</v-icon>
                    <v-icon color="white" x-large>mdi-circle-small</v-icon>
                    <v-icon color="white" x-large>mdi-circle-small</v-icon>
                    <p class="topic_proposition">
                      Analizzare situazioni e suggerire soluzioni, sulla base di
                      esperienze e competenze professionali, col fine di
                      permettere l'adozione delle migliori scelte strategiche e
                      tecnologiche, fondamentali per la crescita di un’azienda
                    </p>
                  </v-col>
                  <!-- <v-col cols="12" class="text-center py-0 my-0">Vedi</v-col>
                  <v-col cols="12" class="py-0 my-0">
                    <v-btn
                      :to="{ name: 'ServiceConsult' }"
                      block
                      text
                      color="white"
                      >Consulenza Tecnica</v-btn
                    >
                  </v-col> -->
                </v-row>
              </v-col>
              <v-col cols="12" v-if="delay_1000"> </v-col>
              <v-col
                data-aos="fade-right"
                v-if="delay_1000"
                cols="12"
                md="5"
                offset-md="1"
                class="mt-6"
              >
                <v-row>
                  <v-col cols="12" class="text-center">
                    <h2 class="target_title">
                      <TextScrambler
                        text="Comunicare"
                        :scrambleDuration="20"
                        class="scrambler-anim-top"
                      />
                    </h2>
                    <p class="mt-2 topic_text">
                      [...è il processo che consente di emettere, trasmettere,
                      dare informazioni o dati, far conoscere qualcosa a
                      qualcuno...]
                    </p>
                    <v-icon color="white" x-large>mdi-circle-small</v-icon>
                    <v-icon color="white" x-large>mdi-circle-small</v-icon>
                    <v-icon color="white" x-large>mdi-circle-small</v-icon>
                    <p class="topic_proposition">
                      Creare interfacce per consentire di acquisire clienti,
                      mostrare prodotti e servizi, raccogliere dati o
                      visualizzare informazioni.
                    </p>
                  </v-col>
                  <!-- <v-col cols="12" class="text-center py-0 my-0">Vedi</v-col>
                  <v-col cols="12" class="py-0 my-0">
                    <v-btn
                      :to="{ name: 'ServiceWebDev' }"
                      block
                      text
                      color="white"
                      >Sviluppo WEB</v-btn
                    >
                  </v-col> -->
                </v-row>
              </v-col>
              <v-col cols="12" v-if="delay_1500"> </v-col>
              <!-- Innovare -->
              <v-col
                data-aos="fade-left"
                v-if="delay_1500"
                cols="12"
                md="5"
                offset-md="5"
                class="mt-6"
              >
                <v-row>
                  <v-col cols="12" class="text-center">
                    <h2 class="target_title">
                      <TextScrambler
                        text="Innovare"
                        :scrambleDuration="20"
                        class="scrambler-anim-top"
                      />
                    </h2>
                    <p class="mt-2 topic_text">
                      [...introduzione di nuovi sistemi, nuovi servizi o
                      prodotti atti a modificare radicalmente o provocare un
                      efficace svecchiamento di ciò che è già esistente...]
                    </p>
                    <v-icon color="white" x-large>mdi-circle-small</v-icon>
                    <v-icon color="white" x-large>mdi-circle-small</v-icon>
                    <v-icon color="white" x-large>mdi-circle-small</v-icon>
                    <p class="topic_proposition">
                      Individuare processi obsoleti, ottimizzare i flussi
                      lavorativi, implementare meccanismi di efficientamento con
                      l’ausilio delle moderne tecnologie informatiche
                    </p>
                  </v-col>
                  <!-- <v-col cols="12" class="text-center py-0 my-0">Vedi</v-col>
                  <v-col cols="12" class="py-0 my-0">
                    <v-btn
                      :to="{ name: 'ServiceDevelopment' }"
                      block
                      text
                      color="white"
                      >Sviluppo software</v-btn
                    >
                  </v-col> -->
                </v-row>
              </v-col>
              <v-col cols="12" v-if="delay_2000"> </v-col>
              <!-- Digitalizzare -->
              <v-col
                data-aos="fade-left"
                v-if="delay_2000"
                cols="12"
                md="5"
                offset-md="1"
                class="mt-6"
              >
                <v-row>
                  <v-col cols="12" class="text-center">
                    <h2 class="target_title">
                      <TextScrambler
                        text="Digitalizzare"
                        :scrambleDuration="20"
                        class="scrambler-anim-top"
                      />
                    </h2>
                    <p class="mt-2 topic_text">
                      [...trasformazione di un entità analogica in una sequenza
                      digitale che può essere archiviata o modificata,
                      conservata più a lungo o trasmessa a distanza in modo più
                      efficiente...]
                    </p>
                    <v-icon color="white" x-large>mdi-circle-small</v-icon>
                    <v-icon color="white" x-large>mdi-circle-small</v-icon>
                    <v-icon color="white" x-large>mdi-circle-small</v-icon>
                    <p class="topic_proposition">
                      Realizzare soluzioni per la conversione dei dati
                      comunemente definiti analogici, su carta, al fine di
                      automatizzare le attività e incrementare le performance
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row class="rounded_row_white black--text pa-6 pb-12 my-12">
              <v-col cols="12" class="text-left d-flex align-center">
                <div>
                  <h1 class="main_heading_on_white">Dove siamo ?</h1>
                </div>
              </v-col>

              <v-col cols="12">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2850.7858334638704!2d7.8212912508002255!3d44.396517911962405!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12cd6345f143dadb%3A0x7a0a6efa70abc173!2sAVMSquare%20Informatica!5e0!3m2!1sit!2sit!4v1647351234495!5m2!1sit!2sit"
                  width="100%"
                  height="450"
                  style="border: 0; box-shadow: 3px 3px 8px 0px black"
                  allowfullscreen=""
                  loading="lazy"
                ></iframe>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TextScrambler from "text-scrambler";
import TypedJsProposition from "../components/TypedJsProposition";
import cardService from "@/components/cardService";
import { VueTyper } from "vue-typer";

import AOS from "aos";
import "aos/dist/aos.css";

export default {
  metaInfo: {
    title: "Soluzioni informatiche al tuo servizio",
    meta: [
      {
        name: "description",
        content:
          "Benvenuto in AVMSquare. Scopri i servizi e ottieni il massimo dalle tecnologie informatiche!",
      },
    ],
  },
  props: {},
  components: {
    cardService,
    TextScrambler,
    TypedJsProposition,
    VueTyper,
  },
  data: () => ({
    delay_500: true,
    delay_1000: true,
    delay_1500: true,
    delay_2000: true,
    delay_2500: true,

    // service_assistenza: {
    //   title: "Assistenza & Consulenza<br/>Informatica",
    //   // subtitle: "Supporto utenti infrastrutture di rete e sicurezza",
    //   link: "OurServicesAssistenzaConsulenza",
    //   img_src: require("@/assets/imgs/soluzioni_assistenza_w.svg"),
    //   img_alt: "Icona assistenza",
    // },
    // service_sviluppo: {
    //   title: "Analisi & Sviluppo<br/>software",
    //   // subtitle: "Analisi, progettazione e sviluppo",
    //   link: "OurServicesSviluppo",
    //   img_src: require("@/assets/imgs/coding_guy_w.svg"),
    //   img_alt: "Icona sviluppo software",
    // },
  }),
  methods: {},
  mounted() {
    // console.log(this.$vuetify.breakpoint);

    AOS.init();
    setTimeout(() => {
      this.delay_500 = true;
    }, 500);
    setTimeout(() => {
      this.delay_1000 = true;
    }, 1000);
    setTimeout(() => {
      this.delay_1500 = true;
    }, 1500);
    setTimeout(() => {
      this.delay_2000 = true;
    }, 2000);
    setTimeout(() => {
      this.delay_2500 = true;
    }, 2500);
  },
  created() {},
  computed: {},
  watch: {},
};
</script>


<style>
/* .vue-typer .custom.char {
  color: black !important;
} */
</style>

<style scoped>
.scrambler-anim-top {
  position: relative;
  top: -10px;
  animation-name: move_up_down_up;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

.span-definition-title {
  font-family: "Fjalla One", sans-serif;
  color: white;
  text-shadow: 5px 5px 3px rgb(0, 0, 0);
}

/* Small screen */
@media only screen and (max-width: 600px) {
  .span-definition-title {
    /* font-size: 2rem !important; */
    /* height: 6vh; */
  }

  .target_title {
    font-size: 1.5rem !important;
    text-shadow: rgb(0, 0, 0) 5px 5px 3px;
  }

  .topic_text {
    font-size: 0.9rem;
    font-style: italic;
  }

  .target_text {
    font-weight: bold;
    font-size: 1.25rem;
  }
  .target_icon {
    font-size: 2.5rem;
  }

  /* .main_heading {
    font-size: 1.8rem;
    text-shadow: black 3px 3px 6px;
  } */

  /* .main_heading_on_white {
    font-size: 1.8rem;
    text-shadow: rgba(0, 0, 0, 0.25) 3px 3px 6px;
  } */

  /* .main_subheading {
    margin-top: 1rem;
    font-size: 1.8rem;
    text-shadow: black 3px 3px 6px;
    line-height: 1;
  } */

  .find_services {
    font-size: 1rem;
    font-weight: bold;
    /* width: 250px; */
    /* display: inline-block; */
  }
  .service-title {
    font-size: 0.9rem;
    font-weight: bold;
    line-height: 1;
    color: black;
  }
}

/* Large screen */
@media only screen and (min-width: 600px) {
  .span-definition-title {
    font-size: 3rem !important;
    height: 10vh;
    margin-left: 1rem;
  }

  .target_title {
    font-size: 2.5rem !important;
    text-shadow: rgb(0, 0, 0) 5px 5px 3px;
  }
  .topic_text {
    font-size: 1rem;
    font-style: italic;
  }
  .target_text {
    font-size: 1.5rem;
    font-weight: bold;
  }

  .target_icon {
    font-size: 5rem;
  }

  .topic_proposition {
    font-size: 1rem;
  }
  /* .main_heading {
    font-size: 2rem;
    text-shadow: black 3px 3px 6px;
  } */

  /* .main_heading_on_white {
    font-size: 2rem;
    text-shadow: rgba(0, 0, 0, 0.25) 3px 3px 6px;
  } */

  /* .main_subheading {
    font-size: 1.6rem;
    text-shadow: black 3px 3px 6px;
    line-height: 1;
  } */

  .find_services {
    font-size: 1.5rem;
    font-weight: bold;
    /* width: 250px; */
    /* display: inline-block; */
  }

  .service_description {
    font-size: 1.2rem;
    font-weight: bold;
    line-height: 1;
    color: black;
    max-width: 150px;
  }

  .gradient_color_top:hover {
    /* background-color:black !important; */
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 60%,
      rgba(29, 29, 29, 1) 80%
    ) !important;
  }
}

/* .service_box:hover {
  transform: scale(1.2);
} */

/* .service_link {
  color: black;
}

.service_link:link {
  text-decoration: none;
}

.service_link:visited {
  text-decoration: none;
}

.service_link:hover {
  text-decoration: none;
}

.service_link:active {
  text-decoration: none;
} */
/* .service_box:hover {
  transform: scale(1.1);
  color:red !important
} */
</style>