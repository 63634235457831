<template>
  <div class="text_fade_proposition">
    <vue-typed-js :startDelay="1000" :strings="[textToType]" :typeSpeed="typeSpeed" cursorChar="">
      <h1 class="typing text-proposition"></h1>
    </vue-typed-js>
  </div>
</template>

<script>
export default {
  props: ["text"],
  components: {},
  data() {
    return {
      textToType: this.text,
      typeSpeed: 8,
    };
  },
  methods: {},
  created() {
    if (this.text == null || this.text == "") {
      this.textToType = "";
    }
  },
  mounted() {},
};
</script>

<style scoped>
.text-proposition {
  font-family: "Roboto Mono", monospace;
  font-weight: 100;
    font-family: "Electrolize", sans-serif;

  /* font-size: 14px; */
  /* text-shadow: 2px 2px 1px black; */

  text-shadow: 1px 1px 1px black, 1px 2px 1px black, 1px 3px 1px black,
    2px 2px 4px black;
}
.text_fade_proposition {
  position: relative;
  top: 0px;
  animation-name: text_fade_step2;
  animation-delay: 1s;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
  opacity: 0;
}
@keyframes text_fade_step2 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Mobile breakpoint */
@media only screen and (max-width: 600px) {
.text-proposition {
  font-size: 14px;
}
}

/* Large screen breakpoint */
@media only screen and (min-width: 600px) {
.text-proposition {
  font-size: 16px;
}
}

</style>